import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import _ from "lodash";
import NumberFormat from "react-number-format";
var MemberDonationsOverview = function (_a) {
    var _b;
    var member = _a.member, showDefault = _a.showDefault;
    var _c = useState(showDefault), show = _c[0], setShow = _c[1];
    var last_donation_date = new Date((_b = member === null || member === void 0 ? void 0 : member.donations.slice().sort(function (a, b) {
        var _a, _b, _c, _d;
        return +new Date((_b = (_a = a.member) === null || _a === void 0 ? void 0 : _a.donations) === null || _b === void 0 ? void 0 : _b.donation_date) <
            +new Date((_d = (_c = b.member) === null || _c === void 0 ? void 0 : _c.donations) === null || _d === void 0 ? void 0 : _d.donation_date);
    })[0]) === null || _b === void 0 ? void 0 : _b.donation_date);
    return (React.createElement(Grid, { container: true, style: {
            borderLeft: "10px #E21D37 solid",
            borderRight: "2px #E21D37 solid",
            borderBottom: "2px #E21D37 solid",
            borderTop: "2px #E21D37 solid",
            padding: "5px",
            borderRadius: "3px",
        } },
        React.createElement(Grid, { xs: 12 },
            React.createElement(Box, { m: 1 },
                React.createElement(Typography, { variant: "body1" }, "Donations Overview"))),
        React.createElement(Grid, { xs: 3 },
            React.createElement(Box, { mx: 1, mb: 1 },
                React.createElement(Typography, { variant: "body2" },
                    "Average Donation:",
                    " ",
                    React.createElement("strong", null,
                        " ",
                        React.createElement(NumberFormat, { value: (member === null || member === void 0 ? void 0 : member.donations.length) > 0
                                ? _.meanBy(member === null || member === void 0 ? void 0 : member.donations, function (d) { return parseInt(d.amount); })
                                : 0, displayType: "text", decimalScale: 2, fixedDecimalScale: true, thousandSeparator: true, prefix: "$" }))),
                React.createElement(Typography, { variant: "body2" },
                    "Total Donated:",
                    " ",
                    React.createElement("strong", null,
                        " ",
                        React.createElement(NumberFormat, { value: _.sumBy(member === null || member === void 0 ? void 0 : member.donations, function (d) {
                                return parseInt(d.amount);
                            }), displayType: "text", decimalScale: 2, fixedDecimalScale: true, thousandSeparator: true, prefix: "$" }))),
                React.createElement(Typography, { variant: "body2" },
                    "Last Donation Date:",
                    React.createElement("strong", null,
                        " ",
                        (member === null || member === void 0 ? void 0 : member.donations.length) > 0
                            ? last_donation_date === null || last_donation_date === void 0 ? void 0 : last_donation_date.toLocaleDateString("en-US", {
                                timeZone: "UTC",
                            }).replace(/\//g, "-")
                            : "N/A")))),
        React.createElement(Grid, { xs: 3 },
            React.createElement(Box, { mx: 1, mb: 1 },
                React.createElement(Typography, { variant: "body2" },
                    "Congressional District:",
                    React.createElement("strong", null, member === null || member === void 0 ? void 0 : member.congressional_district)))),
        React.createElement(Grid, { xs: 2 })));
};
export default MemberDonationsOverview;
