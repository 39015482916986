import React from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { usePage } from "@inertiajs/react";
var useStyles = makeStyles(function (theme) {
    return createStyles({
        donationRow: {
            paddingTop: ".75em",
        },
    });
});
var MemberDonationDetail = function (_a) {
    var member = _a.member, donation = _a.donation;
    var auth = usePage().props.auth;
    var classes = useStyles();
    var leadDispositionGroupIds = [1, 2];
    return (React.createElement(Grid, { container: true, sx: {
            borderLeft: "1px #035399 solid",
            borderRight: "1px #035399 solid",
            borderBottom: "1px #035399 solid",
            borderTop: "1px #035399 solid",
            padding: "5px",
            borderRadius: "3px",
        } },
        React.createElement(Grid, { xs: 4, className: classes.donationRow },
            React.createElement(Grid, { container: true, justifyContent: "center", alignContent: "center" },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Box, { mx: 1, mt: 3 },
                        React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, "Lead Options"))),
                React.createElement(Grid, { xs: 12 })))));
};
export default MemberDonationDetail;
