import React, { useEffect, useState } from "react";
import { router } from "@inertiajs/react";
import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import ConversionTable from "./ConversionTable";
import OfficeLocations from "../components/OfficeLocations";
function Conversions(_a) {
    var selected_month = _a.selected_month, selected_year = _a.selected_year, location = _a.location, use_inactive = _a.use_inactive, policies_by_conversion = _a.policies_by_conversion, formData = _a.formData, department = _a.department;
    var today = new Date();
    var _b = useState(today.getMonth()), month = _b[0], setMonth = _b[1];
    var _c = useState(today.getFullYear()), year = _c[0], setYear = _c[1];
    var _d = useState("all"), currentLocation = _d[0], setCurrentLocation = _d[1];
    var _e = useState(false), useInactive = _e[0], setUseInactive = _e[1];
    var _f = useState(false), ready = _f[0], setReady = _f[1];
    var handleSubmit = function () {
        router.post("/production-boards/d/".concat(department, "/b/conversion"), {
            selected_month: month + 1,
            selected_year: year,
            location: currentLocation,
            use_inactive: useInactive,
        });
    };
    var handleInactive = function () {
        setUseInactive(!useInactive);
    };
    useEffect(function () {
        handleSubmit();
        setReady(true);
    }, [currentLocation, month, year, useInactive, ready]);
    return (React.createElement(Grid, { container: true, xs: 12, spacing: 3 },
        React.createElement(Grid, { xs: 8 },
            React.createElement(OfficeLocations, { setCurrentLocation: setCurrentLocation, currentLocation: currentLocation, department: department })),
        React.createElement(Grid, { xs: 3 },
            React.createElement(FormControl, null,
                React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { onChange: handleInactive, id: "check-inactive" }), label: "Show Inactive Agents" }))),
        React.createElement(Grid, { xs: 9 }, policies_by_conversion && (React.createElement(ConversionTable, { policies_by_conversion: policies_by_conversion, year: year, setYear: setYear, department: department })))));
}
export default Conversions;
