import { router } from "@inertiajs/react";
import { Button, FormControl, Dialog, DialogContent, DialogTitle, List, } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import { upperFirst } from "lodash";
import GenericControllerTextInput from "../../components/GenericControllerTextInput";
export default function MemberUtmAddDialog(_a) {
    var resource = _a.resource, resourceType = _a.resourceType, CRUDOperation = _a.CRUDOperation, showButtonStyles = _a.showButtonStyles, CRUDButtonStyles = _a.CRUDButtonStyles;
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var _c = useForm(), control = _c.control, errors = _c.formState.errors, handleSubmit = _c.handleSubmit, reset = _c.reset;
    var _d = useState(false), isSubmitting = _d[0], setIsSubmitting = _d[1];
    var handleClickOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var submitOptions = {
        preserveScroll: true,
        onStart: function () {
            setIsSubmitting(true);
        },
        onFinish: function () {
            setIsSubmitting(false);
            if (CRUDOperation === "add") {
                reset({
                    resource_name: "",
                });
            }
            handleClose();
        },
    };
    var performCRUDOperation = function (data) {
        switch (CRUDOperation) {
            case "add":
                if (!isSubmitting) {
                    data.type = resourceType;
                    data.resource_name = data[resourceType + "_add"];
                    data.parent_id = resource === null || resource === void 0 ? void 0 : resource.id;
                    router.post("/member/utm", data, submitOptions);
                }
                break;
            case "edit":
                if (!isSubmitting) {
                    data.type = resourceType;
                    data.resource_name = data[resourceType + "_edit"];
                    router.patch("/member/edit-utm/".concat(resource.id), data, submitOptions);
                }
                break;
            case "delete":
                if (!isSubmitting) {
                    switch (resourceType) {
                        case "sources":
                            router.delete("/member/delete-utm-source/".concat(resource.id), submitOptions);
                            break;
                        case "campaigns":
                            router.delete("/member/delete-utm-campaign/".concat(resource.id), submitOptions);
                            break;
                        case "mediums":
                            router.delete("/member/delete-utm-medium/".concat(resource.id), submitOptions);
                            break;
                    }
                }
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: "outlined", sx: showButtonStyles, onClick: handleClickOpen }, CRUDOperation == "edit" ? (React.createElement(EditIcon, null)) : ("".concat(CRUDOperation, " ").concat(resourceType.substring(0, resourceType.length - 1)))),
        React.createElement(Dialog, { open: open, onClose: handleClose, "aria-labelledby": "form-dialog-title" },
            React.createElement(DialogTitle, { id: "form-dialog-title" },
                upperFirst(CRUDOperation),
                " ",
                upperFirst(resourceType.substring(0, resourceType.length - 1))),
            React.createElement(DialogContent, null,
                React.createElement(FormControl, { variant: "outlined" },
                    CRUDOperation === "delete" ? (React.createElement(List, null,
                        "Are you sure you want to delete this",
                        " ",
                        resourceType.slice(0, -1),
                        "?",
                        React.createElement("br", null),
                        resourceType === "sources"
                            ? "This will also delete related campaigns and mediums. \n This operation is unreversable."
                            : resourceType === "campaigns"
                                ? "This will also delete related mediums. \n This operation is unreversable."
                                : "This operation is unreversable.")) : (React.createElement(GenericControllerTextInput, { type: "text", sx: {
                            borderRadius: "0",
                        }, name: resourceType + "_" + CRUDOperation, 
                        // label={resourceType + " " + CRUDOperation}
                        control: control, rules: resource && resource.name !== undefined
                            ? {
                                required: true,
                                validate: function (value) {
                                    return value != resource.name;
                                },
                            }
                            : {
                                required: true,
                            }, error: "resource_name" in errors, defaultValue: CRUDOperation !== "add" &&
                            resource &&
                            resource.name !== undefined
                            ? resource.name
                            : "", label: CRUDOperation === "add"
                            ? "new ".concat(resourceType.substring(0, resourceType.length - 1))
                            : "edit ".concat(resourceType.substring(0, resourceType.length - 1)) })),
                    React.createElement(Button, { "aria-label": CRUDOperation, sx: CRUDButtonStyles, onClick: handleSubmit(performCRUDOperation), disabled: isSubmitting },
                        CRUDOperation,
                        " ",
                        resourceType.substring(0, resourceType.length - 1)),
                    React.createElement(Button, { "aria-label": "cancel", sx: {
                            backgroundColor: "grey",
                            width: "100%",
                            marginTop: 1,
                            color: "#fff",
                            borderRadius: 0,
                            borderColor: "grey",
                            border: 1,
                            "&:hover": {
                                backgroundColor: "#fff",
                                color: "grey",
                            },
                        }, onClick: handleClose, disabled: isSubmitting }, "Cancel"))))));
}
