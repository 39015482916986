import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, ButtonGroup } from "@mui/material";
var TaskFilters = function (_a) {
    var setFilter = _a.setFilter, filter = _a.filter;
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { container: true, xs: 12, justifyContent: "center", alignContent: "center" },
            React.createElement(ButtonGroup, null,
                React.createElement(Button, { onClick: function () { return setFilter(""); }, style: {
                        backgroundColor: filter === "" ? "#E21D37" : "#1E5496",
                        color: "#FFF",
                        textDecoration: "none",
                    } }, "All"),
                React.createElement(Button, { onClick: function () { return setFilter("today"); }, style: {
                        backgroundColor: filter === "today" ? "#E21D37" : "#1E5496",
                        color: "#FFF",
                        textDecoration: "none",
                    } }, "Today's")))));
};
export default TaskFilters;
