var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from "react";
import TextField from "@mui/material/TextField";
import { Divider, ListItemText, MenuItem, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import CreditCardForm from "../../components/CreditCardForm";
import GenericControllerSelect from "../../components/GenericControllerSelect";
import GenericNumberInput from "../../components/GenericNumberInput";
import GenericControllerCheckboxInput from "../../components/GenericControllerCheckboxInput";
import NumberFormat from "react-number-format";
import { agentHasRole } from "../../utils/utils";
import { usePage } from "@inertiajs/react";
import { UtmAutoComplete } from "../../components/UtmAutoComplete";
import { Controller } from "react-hook-form";
export default function OrderAddForm(props) {
    var utmSources = props.utmSources, utmCampaigns = props.utmCampaigns, utmMediums = props.utmMediums, referrals = props.referrals, member = props.member, products = props.products, errors = props.errors, watch = props.watch, control = props.control, getValues = props.getValues;
    var watchCardNumber = watch("payment[card_number]", "");
    var watchPaymentType = watch("payment[type]", "");
    var watchContribution = watch("payment[contribution]", 0);
    var watchProductId = watch("product_id", "");
    var watchUtmSource = watch("utm_source", "");
    var watchInternalSource = watch("internal_source", "");
    var productPrice = 0;
    for (var _i = 0, products_1 = products; _i < products_1.length; _i++) {
        var product = products_1[_i];
        if (product.id == watchProductId) {
            productPrice = product.price;
        }
    }
    var auth = usePage().props.auth;
    var activeProducts = [21];
    var currentProductIds = [
        1, 2, 3, 4, 5, 6, 7, 21, 23, 25, 26, 28, 29, 30, 33, 34, 35, 36
    ];
    if (agentHasRole(auth, "msr")) {
        activeProducts.push.apply(activeProducts, [1, 2, 3, 4, 5, 6, 7, 23, 25, 26, 28, 29, 30, 33, 34, 35, 36]);
    }
    var requirePayment = [
        2, 3, 4, 5, 6, 7, 23, 25, 26, 28, 29, 30, 33, 34, 35, 36
    ];
    var requireRenewal = [1, 2, 3, 4, 5, 6];
    var paymentOptions = [
        {
            id: "check",
            name: "Check",
        },
        {
            id: "cash",
            name: "Cash",
        },
        {
            id: "money-order",
            name: "Money Order",
        },
        {
            id: "credit",
            name: "Credit Card",
        },
    ];
    var renewalOptions = [
        {
            id: "0",
            name: "No",
        },
        {
            id: "1",
            name: "Yes",
        },
    ];
    return (React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
        React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Product"),
                (member === null || member === void 0 ? void 0 : member.recurring) === 1 && (React.createElement("h6", { style: { color: "red", marginBottom: 0 } },
                    "This member has recurring billing",
                    " ")))),
        React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerSelect, { name: "product_id", control: control, label: "Product", customOptions: __spreadArray([
                        { name: "Select Product" }
                    ], products
                        .filter(function (el) {
                        return activeProducts.includes(el.id);
                    })
                        .filter(function (el) {
                        return (member === null || member === void 0 ? void 0 : member.id) ? el.id != 21 : true;
                    }), true).map(function (option, idx) {
                        return (React.createElement(MenuItem, { key: "".concat(option.name, "_").concat(option.id), value: option.id },
                            React.createElement(ListItemText, { primary: option.name })));
                    }), rules: {
                        required: member.id
                            ? false
                            : getValues("donation[amount]") != ""
                                ? false
                                : true,
                    }, error: "product_id" in errors, defaultValue: watchProductId })),
            requirePayment.includes(watchProductId) && (React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerSelect, { name: "payment[type]", control: control, label: "Payment Type", customOptions: paymentOptions.map(function (option, idx) { return (React.createElement(MenuItem, { key: "".concat(name, "_").concat(idx), value: option.id },
                        React.createElement(ListItemText, { primary: option.name }))); }), rules: {
                        required: requirePayment.includes(watchProductId)
                            ? true
                            : false,
                        validate: function (value) {
                            return requirePayment.includes(watchProductId)
                                ? value.length > 0
                                : true;
                        },
                    }, error: errors.payment && "type" in errors.payment, defaultValue: watchPaymentType }))),
            member.id && requireRenewal.includes(watchProductId) && (React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerSelect, { name: "renewal", defaultValue: member.renewal || "", control: control, rules: {
                        required: true,
                    }, label: "Renewal", customOptions: renewalOptions.map(function (option) {
                        return (React.createElement(MenuItem, { key: "".concat(option.name, "_").concat(option.id), value: option.id },
                            React.createElement(ListItemText, { primary: option.name })));
                    }), error: "renewal" in errors }))),
            requirePayment.includes(watchProductId) && (React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericNumberInput, { label: "Contribution Amount", rules: {
                        required: watchProductId == 7 ? true : false,
                        validate: function (value) {
                            return watchProductId == 7 ? value > 0 : true;
                        },
                    }, name: "payment[contribution]", defaultValue: watchContribution, error: errors.contribution &&
                        "contribution" in errors.payment, control: control })))),
        React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
            React.createElement(Divider, { light: true })),
        React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
            ["cash", "check", "money-order"].includes(watchPaymentType) && (React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(Controller, { control: control, name: "payment[usaepay_id]", defaultValue: member.usaepay_id, rules: {
                        required: false,
                    }, render: function (_a) {
                        var field = _a.field;
                        return (React.createElement(TextField, { variant: "outlined", label: "Transaction Id", type: "text", error: errors.payment &&
                                "usaepay_id" in errors.payment, fullWidth: true }));
                    } }))),
            currentProductIds.includes(watchProductId) && (React.createElement(React.Fragment, null,
                !watchInternalSource && (React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(UtmAutoComplete, { limitTags: 1, name: "utm_source", control: control, label: "Referral Source", options: utmSources, rules: {
                            required: true,
                        }, error: "utm_source" in errors, value: null, multiple: false }))),
                !watchUtmSource && (React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(UtmAutoComplete, { limitTags: 1, name: "internal_source", control: control, label: "Internal Source", options: referrals, rules: {
                            required: true,
                        }, error: "internal_source" in errors, value: null, multiple: false }))),
                utmMediums
                    .map(function (el) { return el.member_utm_source_id; })
                    .includes(watchUtmSource === null || watchUtmSource === void 0 ? void 0 : watchUtmSource.id) ? (React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(UtmAutoComplete, { limitTags: 1, name: "utm_medium", control: control, utmSourceID: watchUtmSource === null || watchUtmSource === void 0 ? void 0 : watchUtmSource.id, label: "Referral Campaign", options: utmMediums.filter(function (el) {
                            return el.member_utm_source_id ==
                                (watchUtmSource === null || watchUtmSource === void 0 ? void 0 : watchUtmSource.id);
                        }), rules: {
                            required: true,
                        }, error: "utm_medium" in errors, value: null, multiple: false }))) : (watchUtmSource && (React.createElement(Grid, { xs: 12, md: 6 },
                    React.createElement(UtmAutoComplete, { limitTags: 1, name: "utm_campaign", control: control, utmSourceID: watchUtmSource === null || watchUtmSource === void 0 ? void 0 : watchUtmSource.id, label: "Referral Campaign", options: utmCampaigns.filter(function (el) {
                            return el.member_utm_source_id ==
                                (watchUtmSource === null || watchUtmSource === void 0 ? void 0 : watchUtmSource.id);
                        }), rules: {
                            required: true,
                        }, error: "utm_campaign" in errors, value: null, multiple: false })))))),
            watchProductId && (React.createElement(Grid, { container: true, xs: 12, justifyContent: "flex-end", alignItems: "flex-end" },
                React.createElement(Typography, { variant: "h5" },
                    React.createElement(NumberFormat, { value: Number(productPrice) +
                            Number(watchContribution), displayType: "text", decimalScale: 2, thousandSeparator: true, prefix: "Order Total $" }))))),
        React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
            React.createElement(Divider, { light: true })),
        watchPaymentType === "credit" ? (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
            React.createElement(CreditCardForm, { member: member, control: control, errors: errors, cardNumber: watchCardNumber, showContribution: false }))) : null,
        watchPaymentType === "credit" && (React.createElement(Grid, { xs: 12, md: 6 },
            React.createElement(GenericControllerCheckboxInput, { defaultChecked: (member === null || member === void 0 ? void 0 : member.recurring) ? true : false, name: "payment[recurring]", control: control, label: "Auto Renewal" })))));
}
