import { Paper } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import NumberFormat from "react-number-format";
import BarChartDaily from "../../../components/charts/BarChartDaily";
import PieChartDaily from "../../../components/charts/PieChartDaily";
import TodayMembers from "../../../components/charts/TodayMembers";
var colors = [
    "#ed1b2e",
    "#004b79",
    "#ecb731",
    "#537b35",
    "#371777",
    "#56a0d3",
    "#7f181b",
    "#e4e932",
    "#be0027",
    "#8ec06c",
    "#cf8d2e",
    "#0091cd",
    "#2c9f45",
    "#d7d7d8",
    "#c4dff6",
    "#52325d",
];
export default function ExecutiveChart(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var data = _a.data;
    var summary = data.summary;
    var barLabels = summary.bar_labels;
    var totalMembershipDollars = summary.total_membership_dollars;
    var totalContributionDollars = summary.total_contribution_dollars;
    var graphBarData = Object.create({});
    for (var key in summary === null || summary === void 0 ? void 0 : summary.bar_default) {
        for (var _i = 0, _m = summary === null || summary === void 0 ? void 0 : summary.bar_sub_labels; _i < _m.length; _i++) {
            var label = _m[_i];
            if (Object.keys(graphBarData).includes(label)) {
                graphBarData[label].push((_d = (_c = (_b = summary.bar_default) === null || _b === void 0 ? void 0 : _b[key]) === null || _c === void 0 ? void 0 : _c[label]) !== null && _d !== void 0 ? _d : 0);
            }
            else {
                graphBarData[label] = [
                    (_g = (_f = (_e = summary.bar_default) === null || _e === void 0 ? void 0 : _e[key]) === null || _f === void 0 ? void 0 : _f[label]) !== null && _g !== void 0 ? _g : 0,
                ];
            }
        }
    }
    var datasets = Array();
    var colorCount = 0;
    for (var label in graphBarData) {
        datasets.push({
            label: label,
            data: graphBarData[label],
            backgroundColor: colors[colorCount],
        });
        colorCount += 1;
    }
    var dataBar = {
        labels: barLabels,
        datasets: datasets,
    };
    var lineLabels = summary.line_labels_names;
    var graphLineData = Object.create({});
    for (var key in summary === null || summary === void 0 ? void 0 : summary.line_default) {
        for (var _o = 0, _p = summary === null || summary === void 0 ? void 0 : summary.line_sub_labels; _o < _p.length; _o++) {
            var label = _p[_o];
            if (Object.keys(graphLineData).includes(label)) {
                graphLineData[label].push((_h = summary.line_default[key][label]) !== null && _h !== void 0 ? _h : 0);
            }
            else {
                graphLineData[label] = [(_j = summary.line_default[key][label]) !== null && _j !== void 0 ? _j : 0];
            }
        }
    }
    var lineDataSets = Array();
    colorCount = 0;
    for (var _q = 0, _r = summary.line_sub_labels; _q < _r.length; _q++) {
        var label = _r[_q];
        lineDataSets.push({
            label: label,
            data: graphLineData[label],
            backgroundColor: colors[colorCount],
            borderColor: "#FFF",
            fill: true,
        });
        colorCount += 1;
    }
    var dataLine = {
        labels: lineLabels,
        datasets: lineDataSets,
    };
    var renewalPieData = [];
    for (var key in summary === null || summary === void 0 ? void 0 : summary.pie_renewal_members) {
        renewalPieData.push((_k = summary.pie_renewal_members[key]) !== null && _k !== void 0 ? _k : 0);
    }
    var dataRenewalPie = {
        labels: Object.keys(summary.pie_renewal_members),
        datasets: [
            {
                label: "# of Members",
                data: renewalPieData,
                backgroundColor: colors,
                borderColor: "rgba(255, 255, 255, 1)",
                borderWidth: 1,
            },
        ],
    };
    var newPieData = [];
    for (var key in summary === null || summary === void 0 ? void 0 : summary.pie_new_members) {
        newPieData.push((_l = summary.pie_new_members[key]) !== null && _l !== void 0 ? _l : 0);
    }
    var dataNewPie = {
        labels: Object.keys(summary.pie_new_members),
        datasets: [
            {
                label: "# of Members",
                data: newPieData,
                backgroundColor: colors,
                borderColor: "rgba(255, 255, 255, 1)",
                borderWidth: 1,
            },
        ],
    };
    return (React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
        React.createElement(Grid, { xs: 12, lg: 6 },
            React.createElement(Paper, null,
                React.createElement(TodayMembers, { data: dataLine, title: React.createElement(React.Fragment, null,
                        React.createElement(NumberFormat, { value: summary === null || summary === void 0 ? void 0 : summary.total, displayType: "text", thousandSeparator: true }),
                        " ",
                        summary.line_name) }))),
        React.createElement(Grid, { xs: 12, lg: 6 },
            React.createElement(Paper, null,
                React.createElement(BarChartDaily, { data: dataBar, title: React.createElement(React.Fragment, null,
                        React.createElement(NumberFormat, { value: totalMembershipDollars, displayType: "text", thousandSeparator: true, decimalScale: 2, prefix: "$", suffix: " Membership" }),
                        " ",
                        React.createElement(NumberFormat, { value: totalContributionDollars, displayType: "text", decimalScale: 2, thousandSeparator: true, prefix: "$", suffix: " Contribution" })) }))),
        React.createElement(Grid, { xs: 12, lg: 6 },
            React.createElement(Paper, null,
                React.createElement(PieChartDaily, { data: dataNewPie, title: React.createElement(React.Fragment, null,
                        React.createElement(NumberFormat, { value: summary === null || summary === void 0 ? void 0 : summary.total_new_members, displayType: "text", thousandSeparator: true }),
                        " ",
                        "New Members by UTM Source") }))),
        React.createElement(Grid, { xs: 12, lg: 6 },
            React.createElement(Paper, null,
                React.createElement(PieChartDaily, { data: dataRenewalPie, title: React.createElement(React.Fragment, null,
                        React.createElement(NumberFormat, { value: summary === null || summary === void 0 ? void 0 : summary.total_renewal_members, displayType: "text", thousandSeparator: true }),
                        " ",
                        "Renewal Members by UTM Source") })))));
}
