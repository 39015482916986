var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Grid from "@mui/material/Unstable_Grid2/";
import React from "react";
import route from "ziggy-js";
import DefaultBtn from "./DefaultBtn";
import { useForm } from "@inertiajs/react";
import { useForm as useFormReact } from "react-hook-form";
import GenericControllerTextInput from "./GenericControllerTextInput";
var NotesForm = function (_a) {
    var noteOwner = _a.noteOwner, noteType = _a.noteType, handleAddNotesFlag = _a.handleAddNotesFlag;
    var _b = useFormReact(), control = _b.control, errors = _b.formState.errors;
    var _c = useForm({
        text: "",
        route: window.location.href,
    }), setData = _c.setData, post = _c.post, reset = _c.reset, serverErrors = _c.errors;
    var handleFormSubmit = function (e) {
        e.preventDefault();
        //cannot use route for file upload or currently not working
        post(route(noteType + "_note_create", noteOwner.id), {
            preserveScroll: true,
            onSuccess: function () {
                reset();
                handleAddNotesFlag();
            },
        });
    };
    return (React.createElement(Grid, { container: true, xs: 12, spacing: 2, justifyContent: "center" },
        React.createElement(Grid, { xs: 12 },
            React.createElement(GenericControllerTextInput, { autoFocus: true, fullWidth: true, label: "Note Text", multiline: true, defaultValue: "", type: "text", rules: { required: true }, control: control, customOnChange: function (e) { return setData("text", e.target.value); }, name: "text", error: "text" in __assign(__assign({}, errors), serverErrors), minRows: 5, variant: "outlined" })),
        React.createElement(Grid, { xs: 4 },
            React.createElement(DefaultBtn, { type: "button", onClick: handleFormSubmit, variant: "contained", color: "primary", size: "small", fullWidth: true }, "Save")),
        React.createElement(Grid, { xs: 4 },
            React.createElement(DefaultBtn, { type: "button", onClick: handleAddNotesFlag, variant: "outlined", color: "secondary", size: "small", fullWidth: true }, "Cancel"))));
};
export default NotesForm;
