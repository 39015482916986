import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import FileUploadForm from "../../../components/FileUploadForm";
var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            position: "relative",
            overflow: "auto",
            maxHeight: 300,
            minWidth: 300,
        },
        listSection: {
            backgroundColor: "inherit",
        },
        ul: {
            backgroundColor: "inherit",
            padding: 0,
        },
        round: {
            borderRadius: "3px",
        },
        outline: {
            border: "1px solid #F1F1F1",
            marginBottom: "2px",
        },
    });
});
var AppDocuments = function (_a) {
    var _b, _c;
    var data = _a.data, fileType = _a.fileType;
    var classes = useStyles();
    return (React.createElement(Grid, { container: true, justifyContent: "center", alignContent: "center" },
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Box, { mx: 1, mt: 3 },
                React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, "Attachments"))),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Box, { mx: 1, mt: 3 },
                React.createElement(FileUploadForm, { memberId: data === null || data === void 0 ? void 0 : data.member_id, userId: (_b = data === null || data === void 0 ? void 0 : data.transfer_user_id) !== null && _b !== void 0 ? _b : data === null || data === void 0 ? void 0 : data.created_by, name: fileType, id: data.id }))),
        React.createElement(Grid, { item: true, xs: 12, style: { marginBottom: "14px" } },
            React.createElement(Box, { mx: 1 },
                React.createElement("div", { className: classes.root },
                    React.createElement("ul", null, (_c = data === null || data === void 0 ? void 0 : data.documents) === null || _c === void 0 ? void 0 : _c.map(function (el) {
                        try {
                            return (React.createElement("li", { key: el.id },
                                React.createElement("a", { href: "/file/show?path=".concat(btoa(el.file_name)), target: "_blank" }, el.file_name)));
                        }
                        catch (e) {
                            return React.createElement("li", { key: el.id }, el.file_name);
                        }
                    })))))));
};
export default AppDocuments;
