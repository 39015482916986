import React from "react";
import route from "ziggy-js";
import { Button } from "@mui/material";
function ExportButton(_a) {
    var formData = _a.formData, csrf = _a.csrf;
    return (React.createElement(React.Fragment, null, formData && (React.createElement("form", { method: "post", action: route("export-production-board") },
        React.createElement("input", { type: "hidden", name: "_token", value: csrf }),
        React.createElement("input", { type: "hidden", name: "board", value: formData.board }),
        React.createElement("input", { type: "hidden", name: "department", value: formData.department }),
        React.createElement("input", { type: "hidden", name: "formData", value: JSON.stringify(formData) }),
        React.createElement(Button, { fullWidth: true, type: "submit", style: { borderRadius: 0 }, variant: "contained", color: "secondary" }, "Export")))));
}
export default ExportButton;
