import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { router, usePage } from "@inertiajs/react";
import { useForm } from "react-hook-form";
import { Container, Typography, Paper, Modal, Dialog, DialogContent, } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Layout from "../Layout";
import MemberSpouseInfo from "../../components/MemberSpouseInfo";
import MemberInfoForm from "../../components/MemberInfoForm";
import MemberOptions from "../../components/MemberOptions";
import MemberButton from "../../components/MemberButton";
import Address from "../../components/Address";
import { hasPermission, mailingAddressOptions, residentialAddressOptions, } from "../../utils/utils";
import DisplayErrors from "../../components/DisplayErrors";
import OrderAddForm from "../Order/OrderAddForm";
import { throttle } from "lodash";
import DonationAddForm from "./components/DonationAddForm";
var MemberForm = function (_a) {
    var _b, _c, _d;
    var response_data = _a.response_data, serverErrors = _a.errors;
    var _e = useState(true), menuOpen = _e[0], toggleMenuOpen = _e[1];
    var member = response_data.member, states = response_data.states, referrals = response_data.referrals, products = response_data.products, utm_sources = response_data.utm_sources, utm_campaigns = response_data.utm_campaigns, utm_mediums = response_data.utm_mediums;
    var auth = usePage().props.auth;
    member.formType = "member";
    var _f = useForm({
        shouldUnregister: true,
    }), handleSubmit = _f.handleSubmit, errors = _f.formState.errors, control = _f.control, watch = _f.watch, getValues = _f.getValues;
    var watchAddress = watch("ship_address_1", (_b = member === null || member === void 0 ? void 0 : member.ship_address_1) !== null && _b !== void 0 ? _b : "");
    var watchCity = watch("ship_city", (_c = member === null || member === void 0 ? void 0 : member.ship_city) !== null && _c !== void 0 ? _c : "");
    var watchProspect = watch("product_id", (_d = member === null || member === void 0 ? void 0 : member.product_id) !== null && _d !== void 0 ? _d : "");
    var _g = useState(false), isSubmitting = _g[0], setIsSubmitting = _g[1];
    for (var key in serverErrors) {
        if (key == "credit_card") {
            continue;
        }
        key = key.replace("member.", "");
        errors[key] = serverErrors[key];
    }
    var handleCloseModal = function () {
        setIsSubmitting(false);
    };
    useEffect(function () {
        if (!isEmpty(serverErrors)) {
            setIsSubmitting(false);
        }
    }, [serverErrors]);
    var onSubmit = function (data, e, sendToLead) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (Boolean(data.internal_source)) {
            data.utm_source = data.internal_source.utm_source;
            data.utm_campaign = data.internal_source.utm_campaign;
            data.utm_medium = data.internal_source.utm_medium;
            data.utm_content = data.internal_source.utm_content;
            data.referral_name = data.utm_campaign;
            data.utm_objective = "msr_created";
        }
        else if (Boolean(data.utm_source)) {
            data.utm_source = (_a = data.utm_source) === null || _a === void 0 ? void 0 : _a.code;
            data.utm_campaign =
                (_c = (_b = data.utm_campaign) === null || _b === void 0 ? void 0 : _b.code) !== null && _c !== void 0 ? _c : (_d = data.utm_medium) === null || _d === void 0 ? void 0 : _d.campaign_code;
            data.utm_medium = (_e = data.utm_medium) === null || _e === void 0 ? void 0 : _e.code;
            data.referral_name = data.utm_campaign;
            data.utm_objective = "msr_created";
        }
        var buttonName = e.nativeEvent.srcElement.name;
        if (buttonName === "member_page") {
            data.redirect_to = 'show_member';
            sendToLead = false;
        }
        if (buttonName === "lead_form") {
            data.redirect_to = 'lead_form_with_source';
            sendToLead = true;
        }
        if (Boolean(data.donation_internal_source)) {
            data.donation_utm_source = data.donation_internal_source.utm_source;
            data.donation_utm_campaign =
                data.donation_internal_source.utm_campaign;
            data.donation_utm_medium = data.donation_internal_source.utm_medium;
            data.donation_utm_content =
                data.donation_internal_source.utm_content;
            data.donation_utm_objective = "manual_input";
        }
        else {
            data.donation_utm_source = (_f = data.donation_utm_source) === null || _f === void 0 ? void 0 : _f.code;
            data.donation_utm_campaign =
                (_h = (_g = data.donation_utm_campaign) === null || _g === void 0 ? void 0 : _g.code) !== null && _h !== void 0 ? _h : (_j = data.donation_utm_medium) === null || _j === void 0 ? void 0 : _j.campaign_code;
            data.donation_utm_medium = (_k = data.donation_utm_medium) === null || _k === void 0 ? void 0 : _k.code;
            data.donation_referral_name = data.donation_utm_campaign;
            data.donation_utm_objective = "manual_input";
        }
        if (data.donation.amount == "") {
            data.donation.amount = 0;
        }
        if (data.donation.amount != "") {
            data.donation.status = 'successful';
        }
        if (!isSubmitting) {
            setIsSubmitting(true);
            var post_data = {
                member: data,
                payment: data.payment,
                product_id: data.product_id ? data.product_id : 0,
                renewal: data.renewal,
                donation: data.donation,
                id: 0,
                form_url: "",
            };
            if (!(member === null || member === void 0 ? void 0 : member.id) && sendToLead) {
                post_data.form_url = "dashboard 2 - create member form";
                post_data.id = member.id;
                router.post("/member/create/lead", post_data);
            }
            else if (member === null || member === void 0 ? void 0 : member.id) {
                post_data.form_url = "dashboard 2 - edit member form";
                post_data.id = member.id;
                router.post("/member/edit/" + member.id, post_data);
            }
            else {
                post_data.form_url = "dashboard 2 - create member form";
                router.post("/member/create", post_data);
            }
        }
    };
    return (React.createElement(Layout, { title: !isEmpty(member) ? "Update Member" : "Add New Member", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Container, { maxWidth: false },
            React.createElement(Grid, { container: true, direction: "column", spacing: 4 },
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Typography, { variant: "h4", component: "h3", align: "center" },
                        !isEmpty(member) ? "Update" : "Add New",
                        " Member")),
                !isEmpty(serverErrors) && (React.createElement(Grid, { xs: 12 },
                    React.createElement(DisplayErrors, { errors: serverErrors }))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { container: true, xs: 12, spacing: 4 },
                        React.createElement(Grid, { container: true, component: Paper, xs: 12, spacing: 2 },
                            React.createElement(MemberInfoForm, { member: member, control: control, errors: errors, data: member })))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { container: true, xs: 12, spacing: 4 },
                        React.createElement(Grid, { container: true, component: Paper, xs: 12, spacing: 2 },
                            React.createElement(Address, { data: member, control: control, errors: errors, states: states, options: mailingAddressOptions }),
                            React.createElement(Address, { data: member, control: control, errors: errors, states: states, options: residentialAddressOptions, showResidentialSame: watchAddress != "" && watchCity != "" })))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { container: true, xs: 12, spacing: 4 },
                        React.createElement(Grid, { container: true, xs: 12, component: Paper, spacing: 2 },
                            React.createElement(MemberSpouseInfo, { member: member, control: control, errors: errors, data: member })))),
                hasPermission(auth, "add orders") && (React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { container: true, xs: 12, spacing: 4 },
                        React.createElement(Grid, { container: true, xs: 12, component: Paper, spacing: 2 },
                            React.createElement(OrderAddForm, { member: member, products: products, referrals: referrals, utmSources: utm_sources, utmCampaigns: utm_campaigns, utmMediums: utm_mediums, getValues: getValues, errors: errors, control: control, watch: watch }))))),
                hasPermission(auth, "add donations") && (React.createElement(Grid, { xs: 12 },
                    React.createElement(Grid, { container: true, xs: 12, spacing: 4 },
                        React.createElement(Grid, { container: true, xs: 12, component: Paper, spacing: 2 },
                            React.createElement(DonationAddForm, { member: member, donation_referrals: referrals, donation_utmSources: utm_sources, donation_utmCampaigns: utm_campaigns, donation_utmMediums: utm_mediums, getValues: getValues, errors: errors, control: control, watch: watch }))))),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(MemberOptions, { member: member, control: control })),
                React.createElement(Grid, { xs: 12 },
                    React.createElement(MemberButton, { member: member, watchProspect: watchProspect, handleSubmit: throttle(handleSubmit, 2000), onSubmit: onSubmit }))),
            React.createElement(Modal, { open: isSubmitting, onClose: handleCloseModal },
                React.createElement(Dialog, { open: isSubmitting, onClose: handleCloseModal },
                    React.createElement(DialogContent, { style: { minWidth: "500px", textAlign: "center" } }, "This form is being submitted"))))));
};
export default MemberForm;
