import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from "@mui/material/";
import YearPicker from "../components/YearPicker";
var borders = {
    border: "1px solid #707070",
};
var header = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
};
var headerLeft = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
    borderLeft: "1px solid #707070",
};
var headerRight = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
    borderRight: "1px solid #707070",
};
function ConversionTable(_a) {
    var policies_by_conversion = _a.policies_by_conversion, year = _a.year, setYear = _a.setYear, department = _a.department;
    var columns = department === "Medicare"
        ? [
            { id: "agent", label: "agent" },
            {
                id: "ob_leads_count",
                label: "ob_Leads",
            },
            {
                id: "ob_policies_count",
                label: "ob_Policies",
            },
            {
                id: "ob_conversion_rate",
                label: "ob_Conversion",
            },
            {
                id: "leads_count",
                label: "Leads",
            },
            {
                id: "policies_count",
                label: "Policies",
            },
            {
                id: "conversion_rate",
                label: "Conversion",
            },
        ]
        : [
            { id: "agent", label: "agent" },
            {
                id: "leads_count",
                label: "Leads",
            },
            {
                id: "policies_count",
                label: "Policies",
            },
            {
                id: "conversion_rate",
                label: "Conversion",
            },
        ];
    var colHeaders = department === "Medicare" ? (React.createElement(TableRow, null,
        React.createElement(TableCell, { align: "left", style: headerLeft, colSpan: 1 }, "Agents"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "OB Leads"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "OB Policies Written"),
        React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 }, "OB Conversion Rate"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "Leads"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "Policies Written"),
        React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 }, "Conversion Rate"))) : (React.createElement(TableRow, null,
        React.createElement(TableCell, { align: "left", style: headerLeft, colSpan: 1 }, "Agents"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "Total Leads"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "Total Policies Written"),
        React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 }, "Total Conversion Rate")));
    var calculate_conversion = function (obj, isOB) {
        var leads = 0;
        var policies = 0;
        var ob_leads = 0;
        var ob_policies = 0;
        for (var _i = 0, _a = Object.entries(obj); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            // if isOB, search key for ob
            // if (isOB) {
            key.includes("ob")
                ? key.includes("leads")
                    ? (ob_leads = parseInt(value))
                    : key.includes("policies")
                        ? (ob_policies = parseInt(value))
                        : null
                : null;
            // } else {
            !key.includes("ob")
                ? key.includes("leads")
                    ? (leads = parseInt(value))
                    : key.includes("policies")
                        ? (policies = parseInt(value))
                        : null
                : null;
            // }
        }
        var ob_percentage = (100 * ob_policies) / ob_leads || "N/A";
        var percentage = (100 * policies) / leads || "N/A";
        percentage != "N/A"
            ? (percentage = percentage.toFixed(2).replace(/[.,]00$/, "") + "%")
            : null;
        ob_percentage != "N/A"
            ? (ob_percentage =
                ob_percentage.toFixed(2).replace(/[.,]00$/, "") + "%")
            : null;
        return isOB ? ob_percentage : percentage;
    };
    return (React.createElement(Paper, null, policies_by_conversion && (React.createElement(TableContainer, null,
        React.createElement(Table, null,
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { align: "left", style: headerLeft, colSpan: 1 }),
                    React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 6 },
                        React.createElement(YearPicker, { year: year, setYear: setYear }))),
                colHeaders),
            React.createElement(TableBody, null, policies_by_conversion &&
                policies_by_conversion.map(function (row) {
                    return (React.createElement(TableRow, { role: "checkbox", tabIndex: -1, key: row.agent + "_row" }, columns.map(function (column) {
                        var value = row[column.id];
                        return (React.createElement(TableCell, { key: row.agent +
                                column.id, style: borders, align: !column.id.includes("agent")
                                ? "center"
                                : "left" }, column.id.includes("conversion")
                            ? calculate_conversion(row, column.id.includes("ob"))
                            : value));
                    })));
                })))))));
}
export default ConversionTable;
