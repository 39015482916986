var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { router } from "@inertiajs/react";
import GenericControllerTextInput from "../../../components/GenericControllerTextInput";
import { useForm } from "react-hook-form";
import GenericAutoComplete from "../../../components/GenericAutoComplete";
import { format_date } from "../../../utils/dateUtils";
export default function PolicyReferralsForm(_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var data = _a.data;
    var form_data = data.form_data, serverErrors = data.serverErrors, utmCampaignOptions = data.utm_campaigns, utmContentOptions = data.utm_contents, utmSourceOptions = data.utm_sources, utmMediumOptions = data.utm_mediums, utmObjectiveOptions = data.utm_objectives, insuranceProductsOptions = data.insurance_products, leadTypesOptions = data.lead_types;
    var reportForm = {
        startDate: form_data === null || form_data === void 0 ? void 0 : form_data.start_date,
        endDate: form_data === null || form_data === void 0 ? void 0 : form_data.end_date,
        // memberOrderType: form_data?.member_order_type ?? Array(),
        utm_campaigns: (_b = form_data === null || form_data === void 0 ? void 0 : form_data.utm_campaigns) !== null && _b !== void 0 ? _b : Array(),
        utm_contents: (_c = form_data === null || form_data === void 0 ? void 0 : form_data.utm_contents) !== null && _c !== void 0 ? _c : Array(),
        utm_sources: (_d = form_data === null || form_data === void 0 ? void 0 : form_data.utm_sources) !== null && _d !== void 0 ? _d : Array(),
        utm_mediums: (_e = form_data === null || form_data === void 0 ? void 0 : form_data.utm_mediums) !== null && _e !== void 0 ? _e : Array(),
        utm_objectives: (_f = form_data === null || form_data === void 0 ? void 0 : form_data.utm_objectives) !== null && _f !== void 0 ? _f : Array(),
        insurance_products: (_g = form_data === null || form_data === void 0 ? void 0 : form_data.insurance_products) !== null && _g !== void 0 ? _g : Array(),
        lead_types: (_h = form_data === null || form_data === void 0 ? void 0 : form_data.lead_types) !== null && _h !== void 0 ? _h : Array(),
    };
    var _j = useForm(), handleSubmit = _j.handleSubmit, errors = _j.formState.errors, control = _j.control;
    for (var key in serverErrors) {
        errors[key] = serverErrors[key];
    }
    var onSubmit = function (data) {
        data.start_date = format_date(data.start_date, false);
        data.end_date = format_date(data.end_date, true);
        router.post("/production-boards/d/Policy-Referrals/b/referrals", data);
    };
    return (React.createElement(Grid, { container: true, spacing: 2 },
        React.createElement(Grid, { xs: 12, md: 3 },
            React.createElement(GenericControllerTextInput, { control: control, type: "date", label: "Start Date", name: "start_date", defaultValue: form_data.start_date, error: "start_date" in errors, shrinkLabel: true })),
        React.createElement(Grid, { xs: 12, md: 3 },
            React.createElement(GenericControllerTextInput, { control: control, type: "date", label: "End Date", name: "end_date", defaultValue: form_data.end_date, error: "end_date" in errors, shrinkLabel: true })),
        React.createElement(Grid, { xs: 12, md: 3 },
            React.createElement(GenericAutoComplete, { name: "utm_objectives", label: "UTM Objectives", options: utmObjectiveOptions.map(function (el, index) { return (__assign(__assign({}, el), { id: index + 1 })); }), control: control, value: form_data.utm_objectives, rules: {
                    required: false,
                }, limitTags: 1, error: "utm_objectives" in errors })),
        React.createElement(Grid, { xs: 12, md: 3 },
            React.createElement(GenericAutoComplete, { name: "utm_sources", label: "UTM Sources", options: utmSourceOptions.map(function (el, index) { return (__assign(__assign({}, el), { id: index + 1 })); }), control: control, value: form_data.utm_sources, rules: {
                    required: false,
                }, limitTags: 1, error: "utm_sources" in errors })),
        React.createElement(Grid, { xs: 12, md: 3 },
            React.createElement(GenericAutoComplete, { name: "utm_campaigns", label: "UTM Campaigns", options: utmCampaignOptions.map(function (el, index) { return (__assign(__assign({}, el), { id: index + 1 })); }), control: control, value: form_data.utm_campaigns, rules: {
                    required: false,
                }, limitTags: 1, error: "utm_campaigns" in errors })),
        React.createElement(Grid, { xs: 12, md: 3 },
            React.createElement(GenericAutoComplete, { name: "utm_mediums", label: "UTM Mediums", options: utmMediumOptions.map(function (el, index) { return (__assign(__assign({}, el), { id: index + 1 })); }), control: control, value: form_data.utm_mediums, rules: {
                    required: false,
                }, limitTags: 1, error: "utm_mediums" in errors })),
        React.createElement(Grid, { xs: 12, md: 3 },
            React.createElement(GenericAutoComplete, { name: "utm_contents", label: "UTM Contents", options: utmContentOptions.map(function (el, index) { return (__assign(__assign({}, el), { id: index + 1 })); }), control: control, value: form_data.utm_contents, rules: {
                    required: false,
                }, limitTags: 1, error: "utm_contents" in errors })),
        React.createElement(Grid, { xs: 12, md: 3 },
            React.createElement(GenericAutoComplete, { name: "insurance_products", label: "Insurance Products", options: insuranceProductsOptions, control: control, value: form_data.insurance_products, rules: {
                    required: false,
                }, limitTags: 1, error: "insurance_products" in errors })),
        React.createElement(Grid, { xs: 12, md: 3 },
            React.createElement(GenericAutoComplete, { name: "lead_types", label: "Lead Types", options: leadTypesOptions, control: control, value: form_data.lead_types, rules: {
                    required: false,
                }, limitTags: 1, error: "lead_types" in errors })),
        React.createElement(Grid, { container: true, xs: 12, md: 3, alignContent: "center" },
            React.createElement(Button, { type: "button", onClick: handleSubmit(onSubmit), variant: "contained", color: "primary", fullWidth: true }, "Update"))));
}
