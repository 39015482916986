import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from "@mui/material/";
var borders = {
    border: "1px solid #707070",
};
var header = {
    backgroundColor: "#F7F7F7",
    border: "1px solid #707070",
};
var ProductionBoardsTable = function (_a) {
    var policies_by_agent = _a.policies_by_agent, department = _a.department;
    var columns = department === "Medicare"
        ? [
            { id: "agent", label: "agent" },
            {
                id: "count_sup",
                label: "SUP",
            },
            {
                id: "count_mapd",
                label: "MAPD",
            },
            {
                id: "count_ma",
                label: "MA",
            },
            {
                id: "count_pdp",
                label: "PDP",
            },
            {
                id: "total",
                label: "total",
            },
        ]
        : department === "IMM"
            ? [
                { id: "agent", label: "agent" },
                {
                    id: "count_imm",
                    label: "IMM",
                },
                {
                    id: "count_stm",
                    label: "STM",
                },
                {
                    id: "count_dental",
                    label: "Dental",
                },
                {
                    id: "count_ltc",
                    label: "LTC",
                },
                {
                    id: "count_vision",
                    label: "Vision",
                },
                {
                    id: "count_other",
                    label: "other",
                },
                {
                    id: "count_critical_illness",
                    label: "Critical Illness",
                },
                {
                    id: "total",
                    label: "total",
                },
            ]
            : department === "Rosemark"
                ? [
                    { id: "agent", label: "agent" },
                    {
                        id: "count_life",
                        label: "Life",
                    },
                    {
                        id: "prem_life",
                        label: "Life Premium",
                    },
                    {
                        id: "count_financial",
                        label: "Financial",
                    },
                    {
                        id: "prem_financial",
                        label: "Financial Premium",
                    },
                    {
                        id: "total",
                        label: "total",
                    },
                    {
                        id: "prem_total",
                        label: "Total Premium",
                    },
                ]
                : [];
    var colHeaders = department === "Medicare" ? (React.createElement(TableRow, null,
        React.createElement(TableCell, { align: "left", style: header, colSpan: 1 },
            React.createElement("strong", null, "Agents")),
        React.createElement(TableCell, { style: header, align: "center", colSpan: 1 },
            React.createElement("strong", null, "SUP")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "MAPD")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "MA")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "PDP")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "Total")))) : department === "IMM" ? (React.createElement(TableRow, null,
        React.createElement(TableCell, { align: "left", style: header, colSpan: 1 },
            React.createElement("strong", null, "Agents")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "IMM")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "STM")),
        React.createElement(TableCell, { style: header, align: "center", colSpan: 1 },
            React.createElement("strong", null, "Dental")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "LTC")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "Vision")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "Other")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "Critical Illness")),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
            React.createElement("strong", null, "Total")))) : department === "Rosemark" ? (React.createElement(React.Fragment, null,
        React.createElement(TableRow, null,
            React.createElement(TableCell, { align: "left", style: header, colSpan: 1 },
                React.createElement("strong", null, "Agents")),
            React.createElement(TableCell, { style: header, align: "center", colSpan: 1 },
                React.createElement("strong", null, "Life")),
            React.createElement(TableCell, { style: header, align: "center", colSpan: 1 },
                React.createElement("strong", null, "Life Premium")),
            React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
                React.createElement("strong", null, "Financial")),
            React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
                React.createElement("strong", null, "Financial Premium")),
            React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
                React.createElement("strong", null, "Total")),
            React.createElement(TableCell, { align: "center", style: header, colSpan: 1 },
                React.createElement("strong", null, "Total Premium"))))) : (React.createElement(React.Fragment, null));
    return (React.createElement(React.Fragment, null,
        React.createElement(Paper, null, policies_by_agent && policies_by_agent.length > 0 && (React.createElement(TableContainer, null,
            React.createElement(Table, null,
                React.createElement(TableHead, null, colHeaders),
                React.createElement(TableBody, null,
                    policies_by_agent.length > 0 &&
                        policies_by_agent
                            .sort(function (a, b) {
                            return a.total > b.total
                                ? -1
                                : a.total < b.total
                                    ? 1
                                    : 0;
                        })
                            .map(function (row) {
                            return (React.createElement(TableRow, { role: "checkbox", tabIndex: -1, key: row.agent + "_row" }, columns.map(function (column) {
                                var _a;
                                var value = (_a = row[column.id]) !== null && _a !== void 0 ? _a : 0;
                                return (React.createElement(TableCell, { key: row.agent +
                                        column.id, style: borders, align: !column.id.includes("agent")
                                        ? "center"
                                        : "left" }, column.id.includes("prem")
                                    ? "$" +
                                        parseInt(value).toLocaleString("en-US")
                                    : value));
                            })));
                        }),
                    React.createElement(TableRow, { style: borders },
                        React.createElement(TableCell, { style: borders, key: "agents-total" },
                            React.createElement("strong", null, "Total")),
                        policies_by_agent &&
                            (function () {
                                var columnsForTotalRow = [];
                                var _loop_1 = function (i) {
                                    var _a;
                                    var column_id = Object.keys(policies_by_agent[0])[i];
                                    columnsForTotalRow.push((_a = {},
                                        _a[column_id] = policies_by_agent.reduce(function (prev, agent) {
                                            var _a;
                                            return (prev +
                                                parseInt((_a = agent[Object.keys(agent)[i]]) !== null && _a !== void 0 ? _a : 0));
                                        }, 0),
                                        _a));
                                };
                                for (var i = 1; i <
                                    Object.keys(policies_by_agent[0]).length; i++) {
                                    _loop_1(i);
                                }
                                var columnsObject = {};
                                for (var i = 0; i < columnsForTotalRow.length; i++) {
                                    Object.assign(columnsObject, columnsForTotalRow[i]);
                                }
                                return (React.createElement(React.Fragment, null, columns.map(function (column) {
                                    var value = columnsObject[column.id];
                                    return column.id !=
                                        "agent" &&
                                        column.id !=
                                            "location" ? (React.createElement(TableCell, { key: column.id +
                                            "_total", align: "center", style: borders }, column.id.includes("prem")
                                        ? "$" +
                                            parseInt(value).toLocaleString("en-US")
                                        : value)) : null;
                                })));
                            })())))))),
        policies_by_agent.length == 0 && React.createElement("div", null, "No Results")));
};
export default ProductionBoardsTable;
