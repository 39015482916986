import React, { useState } from "react";
import { router } from "@inertiajs/react";
import { Button, Container, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import _, { throttle } from "lodash";
import { useForm } from "react-hook-form";
import route from "ziggy-js";
import Layout from "../Layout";
import AgentInformation from "../App/components/AgentInformation";
import TaskFormTaskType from "./TaskFormTaskType";
import GenericControllerTextInput from "../../components/GenericControllerTextInput";
export default function TaskForm(_a) {
    var response = _a.response, serverErrors = _a.errors;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var taskable_id = response.taskable_id, taskable_type_pretty = response.taskable_type_pretty, taskable_type = response.taskable_type, agents = response.agents, task_types = response.task_types;
    var _c = useState(false), submitting = _c[0], setSubmitting = _c[1];
    var _d = useForm(), handleSubmit = _d.handleSubmit, errors = _d.formState.errors, control = _d.control;
    var _e = useState(""), taskTypeId = _e[0], setTaskTypeId = _e[1];
    var _f = useState(""), followUpDate = _f[0], setFollowUpDate = _f[1];
    for (var key in serverErrors) {
        errors[key] = serverErrors[key];
    }
    var onSubmit = function (data) {
        var _a;
        data.taskable_type = taskable_type;
        data.taskable_id = taskable_id;
        data.status = "active";
        data.task_type_id = taskTypeId;
        // data.follow_up_date = followUpDate;
        data.transfer_user_id = (_a = data.transfer_user_id) === null || _a === void 0 ? void 0 : _a.id;
        var submitOptions = {
            onStart: function () {
                setSubmitting(true);
            },
            onFinish: function () {
                setSubmitting(false);
            },
        };
        router.post(route("store-task"), data, submitOptions);
    };
    var handleCancel = function () {
        window.history.back();
    };
    return (React.createElement(Layout, { title: "Add ".concat(_.upperFirst(taskable_type_pretty), " Task"), menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Container, { maxWidth: false },
            React.createElement(Grid, { xs: 12, container: true, spacing: 2, direction: "column" },
                React.createElement(Grid, { container: true, xs: 12 },
                    React.createElement(Grid, { container: true, xs: 12 },
                        React.createElement(Grid, { container: true, xs: 6, justifyContent: "flex-start" },
                            React.createElement(Typography, { variant: "h5", component: "h2" }, "Add ".concat(_.upperFirst(taskable_type_pretty), " Task")))),
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Grid, { xs: 12, container: true, component: Paper },
                            React.createElement(TaskFormTaskType, { control: control, taskTypeId: taskTypeId, setTaskTypeId: setTaskTypeId, taskTypes: task_types, errors: errors, followUpDate: followUpDate, setFollowUpDate: setFollowUpDate }),
                            React.createElement(AgentInformation, { agents: agents, errors: errors, control: control, app: "task", deptType: 0 }),
                            React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                                React.createElement(Grid, { xs: 12 },
                                    React.createElement(GenericControllerTextInput, { control: control, variant: "outlined", label: "Task Note", defaultValue: "", multiline: true, type: "text", fullWidth: true, rows: 5, name: "task_note_text", error: "task_note_text" in errors }))))),
                    React.createElement(Grid, { xs: 12 },
                        React.createElement(Grid, { container: true, alignItems: "center", justifyContent: "center", xs: 12, spacing: 2 },
                            React.createElement(Grid, { xs: 3 },
                                React.createElement(Button, { type: "button", variant: "outlined", color: "secondary", onClick: handleCancel, fullWidth: true }, "Cancel")),
                            React.createElement(Grid, { xs: 3 },
                                React.createElement(Button, { type: "button", variant: "contained", color: "primary", onClick: throttle(handleSubmit(onSubmit), 2000), disabled: submitting, fullWidth: true }, "Save Task")))))))));
}
