import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
function MonthPicker(_a) {
    var month = _a.month, setMonth = _a.setMonth;
    return (React.createElement(React.Fragment, null, month >= 0 && (React.createElement(FormControl, null,
        React.createElement(Select, { size: "small", id: "month-select", value: month, onChange: function (e) { return setMonth(e.target.value); } }, months.map(function (m) {
            return (React.createElement(MenuItem, { key: m, value: months.indexOf(m) }, m));
        }))))));
}
export default MonthPicker;
