import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from "@mui/material/";
var borders = {
    border: "1px solid #707070",
};
var header = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
};
var headerLeft = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
    borderLeft: "1px solid #707070",
};
var headerRight = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
    borderRight: "1px solid #707070",
};
function OfficeTable(_a) {
    var policies_by_office = _a.policies_by_office, department = _a.department;
    var calculate_total = function (filter_key, obj) {
        var total = 0;
        for (var _i = 0, _a = Object.entries(obj); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            key.includes(filter_key) ? (total += value) : null;
        }
        return total;
    };
    var columns = department === "Medicare"
        ? [
            { id: "location", label: "group" },
            {
                id: "Supplement",
                label: "SUP",
            },
            {
                id: "MAPD",
                label: "MAPD",
            },
            {
                id: "MA",
                label: "MA",
            },
            {
                id: "PDP",
                label: "PDP",
            },
            {
                id: "total",
                label: "total",
            },
        ]
        : department === "IMM"
            ? [
                { id: "location", label: "group" },
                {
                    id: "IMM",
                    label: "IMM",
                },
                {
                    id: "STM",
                    label: "STM",
                },
                {
                    id: "Dental",
                    label: "Dental",
                },
                {
                    id: "LTC",
                    label: "LTC",
                },
                {
                    id: "Vision",
                    label: "Vision",
                },
                {
                    id: "Other",
                    label: "Other",
                },
                {
                    id: "critical_illness",
                    label: "Critical Illness",
                },
                {
                    id: "total",
                    label: "total",
                },
            ]
            : department === "Rosemark"
                ? [
                    { id: "location", label: "group" },
                    {
                        id: "Life",
                        label: "Life",
                    },
                    {
                        id: "Financial",
                        label: "Financial",
                    },
                    {
                        id: "total",
                        label: "total",
                    },
                ]
                : [];
    var colHeaders = department === "Medicare" ? (React.createElement(React.Fragment, null,
        React.createElement(TableCell, { align: "left", style: headerLeft, colSpan: 1 }, "Group"),
        React.createElement(TableCell, { align: "center", style: headerLeft, colSpan: 1 }, "SUP"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "MAPD"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "MA"),
        React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 }, "PDP"),
        React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 }, "Total"))) : department === "IMM" ? (React.createElement(React.Fragment, null,
        React.createElement(TableCell, { align: "left", style: headerLeft, colSpan: 1 }, "Month"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "IMM"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "STM"),
        React.createElement(TableCell, { style: header, align: "center", colSpan: 1 }, "Dental"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "LTC"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "Vision"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "Other"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "Critical Illness"),
        React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 }, "Total"))) : department === "Rosemark" ? (React.createElement(React.Fragment, null,
        React.createElement(TableCell, { align: "left", style: headerLeft, colSpan: 1 }, "Month"),
        React.createElement(TableCell, { style: header, align: "center", colSpan: 1 }, "Life"),
        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "Financial"),
        React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 }, "Total"))) : (React.createElement(React.Fragment, null));
    return (React.createElement(Paper, null, policies_by_office && (React.createElement(TableContainer, null,
        React.createElement(Table, null,
            React.createElement(TableHead, null,
                React.createElement(TableRow, null, colHeaders)),
            React.createElement(TableBody, null, policies_by_office &&
                policies_by_office.map(function (row) {
                    return (React.createElement(TableRow, { role: "checkbox", tabIndex: -1, key: row.location + "_row" }, columns.map(function (column) {
                        var value = row[column.id];
                        return (React.createElement(TableCell, { key: row.group +
                                column.id, style: borders, align: !column.id.includes("location")
                                ? "center"
                                : "left" }, column.id.includes("total")
                            ? calculate_total("total", row)
                            : value));
                    })));
                })))))));
}
export default OfficeTable;
