var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, } from "@mui/material";
import { Controller } from "react-hook-form";
var GenericControllerDropdownInput = function (_a) {
    var name = _a.name, label = _a.label, id = _a.id, defaultValue = _a.defaultValue, handleChange = _a.handleChange, control = _a.control, rules = _a.rules, _b = _a.InputLabelProps, InputLabelProps = _b === void 0 ? {} : _b, _c = _a.variant, variant = _c === void 0 ? "outlined" : _c, _d = _a.error, error = _d === void 0 ? false : _d, _e = _a.SelectProps, SelectProps = _e === void 0 ? {
        native: true,
    } : _e, shrinkLabel = _a.shrinkLabel, options = _a.options, formControlSX = _a.formControlSX, placeHolder = _a.placeHolder, placeHolderValue = _a.placeHolderValue, children = _a.children, native = _a.native, fullWidth = _a.fullWidth, shouldUnregister = _a.shouldUnregister, props = __rest(_a, ["name", "label", "id", "defaultValue", "handleChange", "control", "rules", "InputLabelProps", "variant", "error", "SelectProps", "shrinkLabel", "options", "formControlSX", "placeHolder", "placeHolderValue", "children", "native", "fullWidth", "shouldUnregister"]);
    return (React.createElement(FormControl, { fullWidth: true, sx: __assign({}, formControlSX) },
        React.createElement(InputLabel, { htmlFor: name, id: name + "_id", shrink: shrinkLabel }, label),
        React.createElement(Controller, { shouldUnregister: shouldUnregister, control: control, name: name, defaultValue: defaultValue, rules: rules, render: function (_a) {
                var _b = _a.field, onChange = _b.onChange, value = _b.value, error = _a.fieldState.error;
                return (React.createElement(Select, { fullWidth: fullWidth, id: name, labelId: name + "_id", label: label, value: value, native: native, variant: variant, onChange: handleChange
                        ? function (e) {
                            handleChange(e);
                            onChange(e);
                        }
                        : onChange, error: !!error },
                    React.createElement(MenuItem, { value: placeHolderValue !== null && placeHolderValue !== void 0 ? placeHolderValue : "" }, placeHolder !== null && placeHolder !== void 0 ? placeHolder : "None"),
                    options));
            } }),
        React.createElement(FormHelperText, null, error && "This field is required.")));
};
export default GenericControllerDropdownInput;
