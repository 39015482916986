import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { orderBy, upperFirst } from "lodash";
import LeadUtmCard from "./LeadUtmCard";
var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            position: "relative",
            overflow: "auto",
            maxHeight: 300,
            minWidth: 300,
        },
        listSection: {
            backgroundColor: "inherit",
        },
        ul: {
            backgroundColor: "inherit",
            padding: 0,
        },
        round: {
            borderRadius: "3px",
        },
        outline: {
            border: "1px solid #F1F1F1",
            marginBottom: "2px",
        },
    });
});
var LeadExtraInfo = function (_a) {
    var extraInfo = _a.extraInfo, lead = _a.lead;
    var classes = useStyles();
    var data = [];
    for (var key in extraInfo) {
        data.push({ key: key, value: extraInfo[key] });
    }
    data = orderBy(data, "key", "asc");
    return (React.createElement(Grid, { container: true, justifyContent: "center", alignContent: "center" },
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Box, { mx: 1, mt: 3 },
                React.createElement(Typography, { variant: "body1", style: { fontWeight: "bold" } }, "Lead Extra Info:"))),
        React.createElement(LeadUtmCard, { lead: lead }),
        React.createElement(Grid, { item: true, xs: 12, style: { marginBottom: "14px" } },
            React.createElement(Box, { mx: 1 },
                React.createElement("div", { className: classes.root }, data.map(function (el, idx) { return (React.createElement(Grid, { key: idx, container: true, style: { borderBottom: "1px dotted #414040" } },
                    React.createElement(Grid, { container: true, item: true, xs: 6 },
                        React.createElement(Typography, { variant: "body2" }, upperFirst(el.key.replaceAll("_", " ")) + ":")),
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(Typography, { variant: "body2", style: { fontWeight: "bold" } }, "".concat(el === null || el === void 0 ? void 0 : el.value))))); }))))));
};
export default LeadExtraInfo;
