export function getTasksFilter(filter) {
    var today = new Date();
    var day = today.getDate();
    day = day.toString.length == 1 ? "0" + day : day;
    var month = today.getMonth() + 1;
    var year = today.getFullYear();
    var currentDate = new Date("".concat(year, "-").concat(month, "-").concat(day)).toDateString();
    switch (filter) {
        case "today":
            return function (tasksToFilter) {
                return tasksToFilter.filter(function (tasktoFilter) {
                    var followUp = new Date(tasktoFilter.follow_up_date).toDateString();
                    return followUp == currentDate;
                });
            };
        default:
            return function (tasksToFilter) {
                return tasksToFilter;
            };
    }
}
export function getTaskableLink(task) {
    var _a, _b, _c, _d, _e;
    if ((task === null || task === void 0 ? void 0 : task.policy) && (task === null || task === void 0 ? void 0 : task.policy.length) > 0) {
        var link = "/member/policy/" +
            ((_a = task === null || task === void 0 ? void 0 : task.policy[0]) === null || _a === void 0 ? void 0 : _a.member_id) +
            "/" +
            task.taskable_id;
        var text = "Policy #".concat(task.taskable_id);
        return { link: link, text: text };
    }
    else if ((task === null || task === void 0 ? void 0 : task.abs_broker) && (task === null || task === void 0 ? void 0 : task.abs_broker.length) > 0) {
        var link = "/abs-broker/show/" + ((_b = task === null || task === void 0 ? void 0 : task.abs_broker[0]) === null || _b === void 0 ? void 0 : _b.user_id);
        var text = (_e = ((_c = task === null || task === void 0 ? void 0 : task.abs_broker[0]) === null || _c === void 0 ? void 0 : _c.user.first_name) +
            " " +
            ((_d = task === null || task === void 0 ? void 0 : task.abs_broker[0]) === null || _d === void 0 ? void 0 : _d.user.last_name)) !== null && _e !== void 0 ? _e : "";
        return { link: link, text: text };
    }
    else {
        return { link: "", text: "" };
    }
}
