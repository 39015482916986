import Grid from "@mui/material/Unstable_Grid2/";
import React from "react";
import GenericAutoComplete from "./GenericAutoComplete";
import GenericControllerTextInput from "./GenericControllerTextInput";
var Referrals = function (_a) {
    var _b, _c;
    var control = _a.control, watch = _a.watch, data = _a.data, errors = _a.errors, referrals = _a.referrals, _d = _a.ignoreGridContainers, ignoreGridContainers = _d === void 0 ? false : _d;
    var selectedRefferal = referrals.filter(function (el) { return el.id == data.referral_id; }).shift() ||
        null;
    var watchReferral = watch("referral", selectedRefferal);
    var component = (React.createElement(React.Fragment, null,
        React.createElement(GenericAutoComplete, { limitTags: 1, name: "referral", control: control, label: "Referral", options: referrals, rules: {
                required: false,
            }, error: "referral" in errors, value: watchReferral, multiple: false }),
        React.createElement(GenericControllerTextInput, { hidden: true, label: "referral_id", control: control, name: "referral_id", defaultValue: (_b = watchReferral === null || watchReferral === void 0 ? void 0 : watchReferral.id) !== null && _b !== void 0 ? _b : "" })));
    return ignoreGridContainers ? (React.createElement(React.Fragment, null, component)) : (React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
        React.createElement(Grid, { container: true, xs: 12, md: 6, xl: 4 }, component),
        (watchReferral === null || watchReferral === void 0 ? void 0 : watchReferral.id) == 18 && (React.createElement(Grid, { xs: 12, sm: 6, xl: 4 },
            React.createElement(GenericControllerTextInput, { name: "referral_other", label: "Referral Other", defaultValue: (_c = data === null || data === void 0 ? void 0 : data.referral_other) !== null && _c !== void 0 ? _c : "", rules: {
                    required: true,
                }, control: control, error: "referral_other" in errors })))));
};
export default Referrals;
