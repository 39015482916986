import React from 'react';
export var agentFilter = function (agents, deptType, current_transfer_user, created_by) {
    agents.forEach(function (agent) {
        var agent_department = [];
        agent.policy_departments.forEach(function (dept) {
            dept.name ? agent_department.push(dept.name) : null;
        });
        agent.userable_type == "App\\Models\\AbsBroker"
            ? agent_department.push("ABS")
            : null;
        agent.id == current_transfer_user
            ? agent_department.push("Currently Transferred")
            : null;
        agent.id == created_by ? agent_department.push("Created by") : null;
        agent_department.length > 0
            ? (agent.policy_departments = agent_department)
            : null;
    });
    var department = [2, 10, 12].includes(deptType)
        ? "IMM"
        : [13].includes(deptType)
            ? "Medicare"
            : [5, 11].includes(deptType)
                ? "Rosemark"
                : null;
    var agentsInDepartment = department
        ? agents.filter(function (agent) {
            return agent.policy_departments.includes(department) ||
                agent.policy_departments.includes("Administration") ||
                agent.policy_departments.includes("Created by") ||
                agent.policy_departments.includes("Currently Transferred") ||
                (department === "Medicare" &&
                    agent.policy_departments.includes("ABS"));
        })
        : agents;
    return agentsInDepartment;
};
export var formattedTransferUserName = function (name, is_deleted, userable_type) {
    userable_type == "App\\Models\\AbsBroker" ? (name += " (ABS)") : null;
    return is_deleted == null ? React.createElement("strong", null, name) : React.createElement("strong", null,
        React.createElement("s", null, name));
};
