import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from "@mui/material/";
import MonthPicker from "../components/MonthPicker";
import YearPicker from "../components/YearPicker";
var borders = {
    border: "1px solid #707070",
};
var header = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
};
var headerLeft = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
    borderLeft: "1px solid #707070",
};
var headerRight = {
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid #707070",
    borderTop: "1px solid #707070",
    borderRight: "1px solid #707070",
};
var columns = [
    { id: "agent", label: "agent" },
    {
        id: "monthly_new_count",
        label: "Monthly New Business",
    },
    {
        id: "monthly_rewrite_count",
        label: "Monthly Rewrites",
    },
    {
        id: "monthly_new_percent",
        label: "Monthly Percent New",
    },
    {
        id: "yearly_new_count",
        label: "Yearly New Business",
    },
    {
        id: "yearly_rewrite_count",
        label: "Yearly Rewrites",
    },
    {
        id: "yearly_new_percent",
        label: "Yearly Percent New",
    },
];
function BusinessTable(_a) {
    var business_by_agents = _a.business_by_agents, month = _a.month, setMonth = _a.setMonth, year = _a.year, setYear = _a.setYear, department = _a.department;
    var calculate_percentage = function (filter_key, obj) {
        var new_business = 0;
        var rewrites = 0;
        for (var _i = 0, _a = Object.entries(obj); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            key.includes(filter_key) && key.includes("new")
                ? (new_business = parseInt(value))
                : key.includes(filter_key) && key.includes("rewrite")
                    ? (rewrites = parseInt(value))
                    : null;
        }
        var total = new_business + rewrites;
        var percentage = (100 * new_business) / total || "N/A";
        percentage != "N/A"
            ? (percentage = percentage.toFixed(2).replace(/[.,]00$/, "") + "%")
            : null;
        return percentage;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Paper, null, business_by_agents && business_by_agents.length > 0 && (React.createElement(TableContainer, null,
            React.createElement(Table, null,
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, { align: "left", style: headerLeft, colSpan: 1 }),
                        React.createElement(TableCell, { align: "right", style: header, colSpan: 2 },
                            React.createElement(MonthPicker, { month: month, setMonth: setMonth })),
                        React.createElement(TableCell, { align: "left", style: header, colSpan: 1 }, year),
                        React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 3 },
                            React.createElement(YearPicker, { year: year, setYear: setYear }))),
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, { align: "left", style: headerLeft, colSpan: 1 }, "Agents"),
                        React.createElement(TableCell, { align: "center", style: headerLeft, colSpan: 1 }, "# New Business"),
                        React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 }, "# Rewrites"),
                        React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 }, "% New Business"),
                        React.createElement(TableCell, { align: "center", style: header, colSpan: 1 }, "# New Business"),
                        React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 }, "# Rewrites"),
                        React.createElement(TableCell, { align: "center", style: headerRight, colSpan: 1 }, "% New Business"))),
                React.createElement(TableBody, null, business_by_agents &&
                    business_by_agents
                        .sort(function (a, b) {
                        return parseInt(a.yearly_rewrite_count) +
                            parseInt(a.yearly_new_count) >
                            parseInt(b.yearly_rewrite_count) +
                                parseInt(b.yearly_new_count)
                            ? -1
                            : parseInt(a.yearly_rewrite_count) +
                                parseInt(a.yearly_new_count) <
                                parseInt(b.yearly_rewrite_count) +
                                    parseInt(b.yearly_new_count)
                                ? 1
                                : 0;
                    })
                        .map(function (row) {
                        return (React.createElement(TableRow, { role: "checkbox", tabIndex: -1, key: row.agent + "_row" }, columns.map(function (column) {
                            var value = row[column.id];
                            return (React.createElement(TableCell, { key: row.agent +
                                    column.id, style: borders, align: !column.id.includes("agent")
                                    ? "center"
                                    : "left" }, column.id.includes("monthly_new_percent")
                                ? calculate_percentage("monthly", row)
                                : column.id.includes("yearly_new_percent")
                                    ? calculate_percentage("yearly", row)
                                    : value));
                        })));
                    })))))),
        business_by_agents.length == 0 && React.createElement("div", null, "No Results")));
}
export default BusinessTable;
