var helperFunctions = {
    getAge: function (birthdate) {
        if (!birthdate) {
            return "N/A";
        }
        if (isNaN(new Date(birthdate).getTime())) {
            return "N/A";
        }
        var ageDifferenceMs = new Date(Date.now() - new Date(birthdate).getTime());
        return Math.abs(ageDifferenceMs.getUTCFullYear() - 1970);
    },
    getDateTime: function (datetime) {
        if (!datetime) {
            return "N/A";
        }
        if (isNaN(new Date(datetime).getTime())) {
            return "N/A";
        }
        var date = new Date(datetime);
        return date.toLocaleDateString("en-US") + " " + date.toLocaleTimeString();
    },
    formatDate: function (date) {
        if (!date) {
            return "";
        }
        if (isNaN(new Date(date).getTime())) {
            return "";
        }
        var newDate = new Date(date + 'T12:00:00');
        return newDate.toLocaleDateString("en-US", { timeZone: "UTC" });
    },
    formatDateDashes: function (date) {
        if (!date) {
            return "";
        }
        if (isNaN(new Date(date).getTime())) {
            return "";
        }
        var newDate = new Date(date + 'T12:00:00');
        return newDate.toLocaleDateString("en-US", { timeZone: "UTC" }).replace(/\//g, '-');
    },
};
export { helperFunctions };
