var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { FormGroup, FormLabel, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import GenericControllerCheckboxInput from "./GenericControllerCheckboxInput";
import styled from "styled-components";
var MemberOptions = function (props) {
    var member = props.member, control = props.control;
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 4 },
        React.createElement(Grid, { xs: 12, container: true, component: Paper, spacing: 2 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Member Options")),
            React.createElement(Grid, { container: true, xs: 12, spacing: 2 },
                React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(FormLabel, { component: "legend" }, "Opt-in Lists"),
                    React.createElement(CheckboxList, null,
                        React.createElement(GenericControllerCheckboxInput, { defaultChecked: member.id
                                ? member.emailing_list
                                    ? true
                                    : false
                                : true, name: "emailing_list", control: control, label: "Email List" }),
                        React.createElement(GenericControllerCheckboxInput, { defaultChecked: member.id
                                ? member.post_mail
                                    ? true
                                    : false
                                : true, name: "post_mail", control: control, label: "Residential Mailing List" }),
                        React.createElement(GenericControllerCheckboxInput, { defaultChecked: member.id
                                ? member.magazine
                                    ? true
                                    : false
                                : true, name: "magazine", control: control, label: "Magazine List" }),
                        React.createElement(GenericControllerCheckboxInput, { defaultChecked: member.recurring ? true : false, name: "recurring", control: control, label: "Recurring Billing" }),
                        React.createElement(GenericControllerCheckboxInput, { defaultChecked: member.daily_news ? true : false, name: "daily_news", control: control, label: "Daily News" }),
                        React.createElement(GenericControllerCheckboxInput, { defaultChecked: member.sbs_newsletter ? true : false, name: "sbs_newsletter", control: control, label: "SBS Newsletter" }),
                        React.createElement(GenericControllerCheckboxInput, { defaultChecked: member.text_notification ? true : false, name: "text_notification", control: control, label: "Text Notification" }))),
                (member === null || member === void 0 ? void 0 : member.id) != null && (React.createElement(Grid, { xs: 12, sm: 6 },
                    React.createElement(FormGroup, null,
                        React.createElement(FormLabel, { component: "legend" }, "Renewal Calls"),
                        React.createElement(GenericControllerCheckboxInput, { defaultChecked: member.renewal_call_date ? true : false, name: "renewal_call_date", control: control, label: "Renewal call was made" }),
                        member.renewal_call_date && (React.createElement(GenericControllerCheckboxInput, { defaultChecked: member.update_renewal_call_date
                                ? true
                                : false, name: "update_renewal_call_date", control: control, label: "Update Renewal call was made" })))))))));
};
var CheckboxList = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    list-style: none;\n    padding: 0;\n    margin-bottom: 0;\n    width: 100%;\n"], ["\n    list-style: none;\n    padding: 0;\n    margin-bottom: 0;\n    width: 100%;\n"])));
export default MemberOptions;
var templateObject_1;
