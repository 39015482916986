import React, { Fragment, useEffect, useState } from "react";
import Layout from "../Layout";
import Grid from "@mui/material/Unstable_Grid2";
import Task from "./Task";
import { Box, Paper, Snackbar, Typography } from "@mui/material";
import { groupBy, isEmpty } from "lodash";
import TaskGroup from "./TaskGroup";
import { getTasksFilter } from "../../utils/taskUtils";
import TasksHeader from "./TasksHeader";
import { Alert } from "@mui/material";
import DisplayErrors from "../../components/DisplayErrors";
var Tasks = function (_a) {
    var _b = _a.tasks, tasks = _b === void 0 ? [] : _b, _c = _a.task, task = _c === void 0 ? [] : _c, flash = _a.flash, serverErrors = _a.errors;
    var _d = useState([]), agentsArray = _d[0], setAgentsArray = _d[1];
    var _e = useState(false), openMessage = _e[0], setOpenMessage = _e[1];
    var _f = useState(true), menuOpen = _f[0], toggleMenuOpen = _f[1];
    var _g = useState([]), groupsArray = _g[0], setGroupsArray = _g[1];
    var _h = useState(""), filter = _h[0], setFilter = _h[1];
    var _j = useState(false), useCompleted = _j[0], setUseCompleted = _j[1];
    if (tasks.length === 0) {
        tasks = task;
    }
    useEffect(function () {
        if (flash.message) {
            setOpenMessage(true);
        }
    }, [flash]);
    useEffect(function () {
        task.length == 1 // if has one task only, return
            ? (function () {
                return;
            })()
            : (function () {
                var groups = [];
                var tasksForFilter = !useCompleted
                    ? tasks.filter(function (task) {
                        return task.status != "completed";
                    })
                    : tasks;
                var tasksFilter = getTasksFilter(filter);
                var taskGroups = groupBy(tasksFilter(tasksForFilter), "task_type.name");
                Object.keys(taskGroups).forEach(function (group) {
                    groups.push([group, taskGroups[group]]);
                });
                setGroupsArray(groups);
            })();
    }, [tasks, filter, useCompleted]);
    return (React.createElement(Layout, { title: "Tasks", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        !isEmpty(serverErrors) && (React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Box, { my: 2 },
                    React.createElement(DisplayErrors, { errors: serverErrors }))))),
        React.createElement(Grid, { xs: 12 }, openMessage && (React.createElement(Box, { mb: 1 },
            React.createElement(Snackbar, { open: openMessage, anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                }, autoHideDuration: 3000, onClose: function () { return setOpenMessage(false); } },
                React.createElement(Alert, { onClose: function () { return setOpenMessage(false); }, severity: (flash === null || flash === void 0 ? void 0 : flash.message.includes("Error:"))
                        ? "error"
                        : "success" }, flash === null || flash === void 0 ? void 0 : flash.message))))),
        tasks.length >= 1 && task.length == 0 && (React.createElement(TasksHeader, { setUseCompleted: setUseCompleted, useCompleted: useCompleted, setFilter: setFilter, filter: filter })),
        React.createElement(Grid, { xs: 12 },
            tasks.length > 0 &&
                groupsArray.map(function (group) {
                    return (React.createElement(TaskGroup, { key: group[0], tasks: group[1], groupName: group[0], agentsArray: agentsArray, setAgentsArray: setAgentsArray }));
                }),
            task.length == 1 && (React.createElement(Fragment, { key: "task_fragment_" + task[0].id },
                React.createElement(Box, { mb: 1, key: task[0].id, id: "task_".concat(task[0].id) },
                    React.createElement(Paper, null,
                        React.createElement(Task, { task: task[0], key: "task_" + task[0].id, agentsArray: agentsArray, setAgentsArray: setAgentsArray }))))),
            task.length == 0 && tasks.length == 0 && (React.createElement(Typography, { variant: "h5", component: "h2" }, "No Tasks")))));
};
export default Tasks;
